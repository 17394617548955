import "./summary-card.css";

const SumnaryCard = ({ label, value, icon, linkLabel, linkFunction }) => {
    return (
        <div className={icon ? "summary-card-container" : "summary-card-container full"}>
            {icon && <img src={icon} alt=""/>}
            {label && value && (
                <div className="card-content-container">
                    <div className="card-content-label">{label}</div>
                    <div className="card-content-value">{value}</div>
                </div>
            )}
            {linkLabel && (
                <div className="card-content-comment" onClick={linkFunction}>
                    {linkLabel}
                </div>
            )}
        </div>
    );
};

export default SumnaryCard;
