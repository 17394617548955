import { useTranslation } from "react-i18next";
import "./order-page-header.css";
import { useNavigate } from "react-router-dom";

const OrderPageHeader = ({ userInfo, showBackLink }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleBackClicked = () => {
        navigate("/search");
    };

    return (
        <header className="order-page-header-container">
            <span className="retail-header">{userInfo.retailName}</span>
            {showBackLink && (
                <div className="back-link" onClick={handleBackClicked}>
                    <img
                        alt=""
                        src={require("../../assets/img/arrow_left.svg").default}
                    />
                    <span>{t("header.back_to_search")}</span>
                </div>
            )}
        </header>
    );
};

export default OrderPageHeader;
