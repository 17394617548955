import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logoImg from "../../assets/img/Kohltech_white.png";
import "./header.css";

const Header = ({ user, setUser }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogoutClick = () => {
        setUser(null);
        navigate("/");
        localStorage.clear();
    };

    return (
        <header className="header-container">
            <img className="logo-img" src={logoImg} alt="logo" />
            {user ? (
                <div className="content-group">
                    <label className="logout_btn" onClick={handleLogoutClick}>
                        {t("header.log_out")}
                    </label>
                    <img
                        alt=""
                        src={require("../../assets/img/account.svg").default}
                    />
                </div>
            ) : (
                ""
            )}
        </header>
    );
};

export default Header;
