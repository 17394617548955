import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./table-card.css";
import StatusPill from "../status-pill/status-pill";
import BackorderStatus from "../backorder-status/backorder-status";

const TableCard = ({ keys, item, isMobile, collapse, setCollapse }) => {
    const { t } = useTranslation();
    const columnRefs = useRef([]);
    const [expendedRow, setExpendedRow] = useState(false);
    const [width, setWidth] = useState("100%");
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (collapse) {
            setExpendedRow(false);
            setCollapse(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, collapse]);

    useEffect(() => {
        if (expendedRow) {
            setWidth(`${100 + 50 * item.subRows.length}%`);
        } else {
            setWidth(`100%`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expendedRow]);

    const handleDotClick = (index) => {
        if (columnRefs.current[index]) {
            columnRefs.current[index].scrollIntoView({
                behavior: "smooth",
                inline: "end",
                block: "nearest",
            });
            setSelectedIndex(index);
        }
    };

    const getDotImg = (index) => {
        if (index === selectedIndex) {
            return require("../../assets/img/dot_selected.svg").default;
        }
        return require("../../assets/img/dot_unselected.svg").default;
    }

    return (
        <div
            className={
                expendedRow
                    ? "table-card-container scrollable"
                    : "table-card-container"
            }
        >
            <table className="table-card" style={{ width: `${width}` }}>
                <tbody>
                    {keys.map((key, index) => (
                        <tr key={index}>
                            {/* title */}
                            <td>{t(key.label)}</td>

                            {/* first colum */}
                            {key.key === "currentStatus" && (
                                <td
                                    ref={(el) => (columnRefs.current[0] = el)}
                                    className={
                                        item[key.key].value === "collapse"
                                            ? "dark-blue"
                                            : ""
                                    }
                                    onClick={() => {
                                        if (
                                            item[key.key].value === "collapse"
                                        ) {
                                            setExpendedRow(!expendedRow);
                                        }
                                    }}
                                >
                                    <StatusPill
                                        status={item[key.key].value}
                                        collapseValue={expendedRow}
                                        isMobile={isMobile}
                                    />
                                </td>
                            )}
                            {key.key === "backOrdered" && (
                                <td>
                                    <BackorderStatus
                                        boValue={item[key.key].value}
                                        boComment={item.backOrderComment}
                                    />
                                </td>
                            )}
                            {key.key !== "currentStatus" &&
                                key.key !== "backOrdered" && (
                                    <td
                                        className={
                                            item[key.key].clickFunction
                                                ? "clickable underline"
                                                : ""
                                        }
                                        key={key + index}
                                        onClick={item[key.key].clickFunction}
                                    >
                                        {item[key?.key]?.value}
                                    </td>
                                )}
                            {/* expended columns */}
                            {expendedRow &&
                                item.subRows.map((subRow, i) => {
                                    if (key.key === "currentStatus") {
                                        return (
                                            <td
                                                key={key.key + i}
                                                ref={(el) =>
                                                    (columnRefs.current[i + 1] =
                                                        el)
                                                }
                                                onClick={() => {
                                                    if (
                                                        subRow[key.key]
                                                            .value ===
                                                        "collapse"
                                                    ) {
                                                        setExpendedRow(
                                                            !expendedRow
                                                        );
                                                    }
                                                }}
                                            >
                                                <StatusPill
                                                    status={
                                                        subRow[key.key].value
                                                    }
                                                    collapseValue={expendedRow}
                                                    isMobile={isMobile}
                                                />
                                            </td>
                                        );
                                    }
                                    if (key.key === "backOrdered") {
                                        return (
                                            <td key={key.key + i}>
                                                <BackorderStatus
                                                    boValue={
                                                        subRow[key.key].value
                                                    }
                                                    boComment={
                                                        subRow.backOrderComment
                                                    }
                                                />
                                            </td>
                                        );
                                    }
                                    if (
                                        key.key !== "currentStatus" &&
                                        key.key !== "backOrdered"
                                    ) {
                                        return (
                                            <td
                                                className={
                                                    subRow[key.key].clickFunction
                                                        ? "clickable underline"
                                                        : ""
                                                }
                                                key={key.key + i}
                                                onClick={
                                                    subRow[key.key].clickFunction
                                                }
                                            >
                                                {subRow[key.key].value}
                                            </td>
                                        );
                                    }
                                    return "";
                                })}
                        </tr>
                    ))}
                </tbody>
            </table>

            {expendedRow && (
                <div className="dots-container">
                    {[item, ...item.subRows].map((_, index) => (
                        <span key={index} onClick={() => handleDotClick(index)}>
                            <img
                                src={
                                    getDotImg(index)
                                }
                                alt="dot-icon"
                            />
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TableCard;
