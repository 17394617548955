import { useNavigate } from "react-router-dom";
import OrderPageLayout from "../../components/layout/order-page-layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../components/table/table";
import {
    mobileOrderDetailHeader,
    orderDetailTableHeader,
    orderDetailTableRowKey,
    searchResponseTableHeader,
} from "../../constant/constant";
import { fetchOrderInfobyONorPO, getToken, isTokenExpired } from "../../services/api";
import SumnaryCard from "../../components/summary-card/summary-card";
import SidePanel from "../../components/side-panel/side-panel";
import SummaryBox from "../../components/summary-box/summary-box";
import SortButton from "../../components/sort-button/sort-button";
import Spinner from "../../components/spinner/spinner";
import "./order-detail-page.css";
import TableCardWrapper from "../../components/table-card-wrapper/table-card-wrapper";
import Modal from "../../components/modal/modal";

const OrderDetailPage = ({
    userInfo,
    setUserInfo,
    selectedOrder,
    orders,
    setOrders,
    setSelectedOrder,
}) => {
    const showFooter = true;
    const showBackLink = true;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [summaryCommentOpen, setSummaryCommentOpen] = useState(false);
    const [lineCommentOpen, setLineCommentOpen] = useState(false);
    const [lineDetailCommentOpen, setlineDetailCommmetOpen] = useState(false);
    const [summaryArray, setSummaryArray] = useState([]);
    const [modalTitle, setModalTile] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [sidePanelTitle, setSidePanelTile] = useState("");
    const [sidePanelContent, setSidePanelContent] = useState("");
    const [sortBtnClicked, setSortBtnClicked] = useState({
        itemNumberClicked: false,
        deliveyDateClicked: false,
        backorderedClicked: false,
    });
    const [collapse, setCollapse] = useState(true);
    const [lastSortBtn, setLastSortBtn] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!userInfo) {
            const userData = localStorage.getItem('userInfo');
            const token = getToken();
            if (!userData || isTokenExpired(token)) {
                navigate("/");
            }
            setUserInfo(JSON.parse(userData));
        }

        if (selectedOrder) {
            getHeaderSummary();
            if (!(selectedOrder.lineItems?.length > 0)) {
                // if select from multiple orders
                fetchOrderDetail(selectedOrder.orderNumber).then((data) => {
                    setSelectedOrder(data[0]);
                    const tempOrders = orders.map((order) => {
                        if (order.orderNumber === data[0].orderNumber) {
                            return data[0];
                        }
                        return order;
                    });
                    setOrders(tempOrders);
                    setLoading(false);
                });
            } else {
                getRows();
            }
        } else {
            const urlArray = window.location.href.split('/');
            const orderId = urlArray[urlArray.length-1];
            fetchOrderDetail(orderId).then((data) => {
                setSelectedOrder(data[0]);
                setOrders(data);
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, selectedOrder]);

    const getCurrentStatus = (lineItem) => {
        if (lineItem.items?.length > 1) {
            return "collapse";
        }
        if (lineItem.shipped > 0) {
            return "shipped";
        }
        if (lineItem.finished > 0) {
            return "finished";
        }
        if (lineItem.production > 0) {
            return "production";
        }
        return "ordered";
    };

    const fetchOrderDetail = async (orderNumber) => {
        try {
            setLoading(true);
            const data = fetchOrderInfobyONorPO(orderNumber);
            return data;
        } catch (err) {
            console.error("Fetch order detail failed. Please try again", err);
        }
    };

    const getImg = (item, superItem) => {
        return item?.lineDetail?.img || superItem?.lineDetail?.img
            ? `<img src="${
                  item.description !== undefined
                      ? item.lineDetail.img
                      : superItem?.lineDetail.img
              }" alt='${t("sidePanel.line_detail_img_alt")}'"> <br/> <br/>`
            : "";
    };

    const getDescription = (des) => {
        return des
            .split("\r\n")
            .map((i, key) => {
                return `<div key=${key}>${i}</div>`;
            })
            .join(" ");
    };

    const getRowObject = (item, keys, superItem) => {
        const row = {};
        keys.forEach((key) => {
            row[key] = {
                value: item[key] !== undefined ? item[key] : superItem?.[key],
            };
            if (key === "currentStatus") {
                row[key] = {
                    value: getCurrentStatus(item),
                };
            }
            if (key === "description") {
                row[key] = {
                    value:
                        item[key] !== undefined ? item[key] : superItem?.[key],
                    clickFunction: () => {
                        setSidePanelTile(t("sidePanel.line_detail_title"));
                        const content = `${getImg(item, superItem)} <p>${
                            item[key] !== undefined
                                ? getDescription(item.lineDetail.description)
                                : getDescription(
                                      superItem?.lineDetail.description
                                  )
                        }</p>`;
                        setSidePanelContent(content);
                        setlineDetailCommmetOpen(true);
                    },
                };
            }
            if (key === "comment" && item.comment !== "") {
                row.comment.clickFunction = () => {
                    setModalTile(t("sidePanel.line_comments"));
                    setModalContent(item[key]);
                    setLineCommentOpen(true);
                };
            }
        });
        row.backOrderComment = item.backOrderComment;
        return row;
    };

    const getRows = () => {
        setHeaders(orderDetailTableHeader);
        const rows = [];
        const lineItems = selectedOrder.lineItems;
        lineItems.forEach((lineItem) => {
            const row = getRowObject(lineItem, orderDetailTableRowKey);
            if (lineItem.items?.length > 1) {
                // add collapse rows
                row.subRows = [];
                lineItem.items.forEach((item) => {
                    const subRow = getRowObject(
                        item,
                        orderDetailTableRowKey,
                        lineItem
                    );
                    row.subRows.push(subRow);
                });
            }
            rows.push(row);
        });
        setRows(rows);
    };

    const getHeaderSummary = () => {
        const summaryArray = [];
        searchResponseTableHeader.forEach((header) => {
            summaryArray.push({
                label: t(header.label),
                value: selectedOrder[header.key],
            });
        });
        setSummaryArray(summaryArray);
    };

    const openComment = () => {
        setSummaryCommentOpen(true);
    };

    const sort = (array, key, order = "asc") => {
        return [...array].sort((a, b) => {
            if (!a[key] || !b[key]) return 0;
            if (typeof a[key].value === "string") {
                return order === "asc"
                    ? a[key].value.localeCompare(b[key].value)
                    : b[key].value.localeCompare(a[key].value);
            } else {
                return order === "asc"
                    ? a[key].value - b[key].value
                    : b[key].value - a[key].value;
            }
        });
    };

    const handleSortClicked = (value, clickedBtn) => {
        setCollapse(true);
        setTimeout(() => {
            if (clickedBtn === "itemNumber") {
                const newRows = sort(
                    rows,
                    "lineNumber",
                    value ? "desc" : "asc"
                );
                setLastSortBtn("itemNumber");
                setRows(newRows);
                setSortBtnClicked({
                    itemNumberClicked: !sortBtnClicked.itemNumberClicked,
                    deliveyDateClicked: false,
                    backorderedClicked: false,
                });
            } else if (clickedBtn === "deliveryDate") {
                let tempRow = [...rows];
                if (lastSortBtn === "itemNumber") {
                    const temp = sort(rows, "lineNumber", "asc");
                    tempRow = [...temp];
                }
                const newRows = sort(
                    tempRow,
                    "deliveryDate",
                    value ? "desc" : "asc"
                );
                setLastSortBtn("deliveryDate");
                setRows(newRows);
                setSortBtnClicked({
                    itemNumberClicked: false,
                    deliveyDateClicked: !sortBtnClicked.deliveyDateClicked,
                    backorderedClicked: false,
                });
            } else {
                let tempRow = [...rows];
                if (lastSortBtn === "itemNumber") {
                    const temp = sort(rows, "lineNumber", "asc");
                    tempRow = [...temp];
                }
                const newRows = sort(
                    tempRow,
                    "backOrdered",
                    value ? "desc" : "asc"
                );
                setLastSortBtn("backOrdered");
                setRows(newRows);
                setSortBtnClicked({
                    itemNumberClicked: false,
                    deliveyDateClicked: false,
                    backorderedClicked: !sortBtnClicked.backorderedClicked,
                });
            }
        });
    };

    const closeModal = () => {
        setLineCommentOpen(false);
    };

    return (
        <div>
            {loading && <Spinner />}
            {userInfo ? (
                <OrderPageLayout
                    userInfo={userInfo}
                    showFooter={showFooter}
                    showBackLink={showBackLink}
                >
                    <SidePanel
                        open={summaryCommentOpen}
                        setOpen={setSummaryCommentOpen}
                        panelTitle={t("sidePanel.order_comments")}
                        panelContent={selectedOrder?.orderComment}
                    />
                    <Modal
                        isOpen={lineCommentOpen}
                        onClose={closeModal}
                        modalTitle={modalTitle}
                        modalContent={modalContent}
                    />
                    <SidePanel
                        open={lineDetailCommentOpen}
                        setOpen={setlineDetailCommmetOpen}
                        panelTitle={sidePanelTitle}
                        panelContent={sidePanelContent}
                    />
                    <div className="order-detail-container">
                        <div className="order-detail-page-title">
                            {t("order_detail_page.title")}
                        </div>
                        <div className="order-detail-header-row">
                            <SummaryBox summaryArray={summaryArray} />
                            <div className="card-group">
                                <SumnaryCard
                                    icon={
                                        require("../../assets/img/doc.svg")
                                            .default
                                    }
                                    label={t("order_detail_page.ordered")}
                                    value={selectedOrder?.totalOrderQuantity}
                                />
                                <SumnaryCard
                                    icon={
                                        require("../../assets/img/car.svg")
                                            .default
                                    }
                                    label={t("order_detail_page.shipped")}
                                    value={selectedOrder?.totalShippedQuantity}
                                />
                                <SumnaryCard
                                    linkLabel={t(
                                        "order_detail_page.view_comments"
                                    )}
                                    linkFunction={openComment}
                                />
                            </div>
                        </div>
                        <div className="order-detail-content-container">
                            <div className="order-detail-subtitle">
                                {t("order_detail_page.sub_title")}
                            </div>
                            <div className="sort-group">
                                <div className="sort-title">
                                    {t("order_detail_page.sort_by")}
                                </div>
                                <div className="sort-btn-group">
                                    <SortButton
                                        clicked={
                                            sortBtnClicked.itemNumberClicked
                                        }
                                        label={t(
                                            "order_detail_page.item_number"
                                        )}
                                        btnOnClicked={(value) => {
                                            handleSortClicked(
                                                value,
                                                "itemNumber"
                                            );
                                        }}
                                    />
                                    <SortButton
                                        clicked={
                                            sortBtnClicked.deliveyDateClicked
                                        }
                                        label={t(
                                            "order_detail_page.delivery_date"
                                        )}
                                        btnOnClicked={(value) => {
                                            handleSortClicked(
                                                value,
                                                "deliveryDate"
                                            );
                                        }}
                                    />
                                    <SortButton
                                        clicked={
                                            sortBtnClicked.backorderedClicked
                                        }
                                        label={t(
                                            "order_detail_page.backorder_sort"
                                        )}
                                        btnOnClicked={(value) => {
                                            handleSortClicked(
                                                value,
                                                "backOrdered"
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            {!isMobile && (
                                <Table
                                    headers={headers}
                                    keys={orderDetailTableRowKey}
                                    rows={rows}
                                    collapse={collapse}
                                    setCollapse={setCollapse}
                                ></Table>
                            )}
                            {isMobile && (
                                <TableCardWrapper
                                    headers={mobileOrderDetailHeader}
                                    rows={rows}
                                    isMobile={isMobile}
                                    collapse={collapse}
                                    setCollapse={setCollapse}
                                />
                            )}
                        </div>
                    </div>
                </OrderPageLayout>
            ) : (
                ""
            )}
        </div>
    );
};

export default OrderDetailPage;
