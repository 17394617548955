export const searchResponseTableHeader = [
    { label: "table.on", key: "orderNumber" },
    { label: "table.po", key: "customerPO" },
    { label: "table.order_date", key: "dateOrdered" },
    { label: "table.delivery_date", key: "deliveryDate" },
];

export const orderRowKey = [
    "orderNumber",
    "customerPO",
    "dateOrdered",
    "deliveryDate",
];

export const  orderDetailTableHeader = [
    { label: "table.number", key: "lineNumber" },
    { label: "table.description", key: "description" },
    { label: "table.width", key: "width" },
    { label: "table.height", key: "height" },
    { label: "table.current_status", key: "currentStatus" },
    { label: "table.ordered", key: "ordered" },
    { label: "table.production", key: "production" },
    { label: "table.finished", key: "finished" },
    { label: "table.shipped", key: "shipped" },
    { label: "table.backordered", key: "backOrdered" },
    { label: "table.delivery_date", key: "deliveryDate" },
    { label: "table.comment", key: "comment" },
]

export const orderDetailTableRowKey = [
    'lineNumber',
    'description',
    'width',
    'height',
    'currentStatus',
    'ordered',
    'production',
    'finished',
    'shipped',
    'backOrdered',
    'deliveryDate',
    'comment',
]

export const mobileOrderDetailHeader = [
    { label: "table.number", key: "lineNumber" },
    { label: "table.description", key: "description" },
    { label: "table.current_status", key: "currentStatus" },
    { label: "table.ordered", key: "ordered" },
    { label: "table.production", key: "production" },
    { label: "table.finished", key: "finished" },
    { label: "table.shipped", key: "shipped" },
    { label: "table.backordered", key: "backOrdered" },
    { label: "table.comment", key: "comment" },
    { label: "table.delivery_date", key: "deliveryDate" },
]