import './spinner.css';

const Spinner = () => {

    return (<>
        <div className="overlay"></div>
        <div className="spinner"></div>
    </>)
}

export default Spinner;