import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchOrderInfobyONorPO, getToken, isTokenExpired } from "../../services/api";
import Table from "../../components/table/table";
import {
    orderRowKey,
    searchResponseTableHeader,
} from "../../constant/constant";
import Spinner from "../../components/spinner/spinner";
import "./search-page.css";

const SearchPage = ({
    userInfo,
    setUserInfo,
    orders,
    setOrders,
    setSelectedOrder,
}) => {
    const [orderNumber, setOrderNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [cpo, setCpo] = useState("");
    const [rows, setRows] = useState([]);
    const [onDisable, setOnDisable] = useState(false);
    const [cpoDisable, setCpoDisable] = useState(false);
    const [searched, setSearched] = useState(false);
    const [erorrMessage, setErrorMessage] = useState("");
    const setter = {
        on: {
            setValue: setOrderNumber,
            setDisable: setCpoDisable,
        },
        cpo: {
            setValue: setCpo,
            setDisable: setOnDisable,
        },
    };
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userInfo) {
            const userData = localStorage.getItem('userInfo');
            const token = getToken();
            if (!userData || isTokenExpired(token)) {
                navigate("/");
            }
            setUserInfo(JSON.parse(userData));
        }
        if (orders) {
            setSearched(true);
            getRows(orders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = (e, key) => {
        setter[key].setValue(e.target.value);
        if (e.target.value.length > 0) {
            setter[key].setDisable(true);
        } else {
            setter[key].setDisable(false);
        }
    };

    const handleClear = () => {
        setOrderNumber("");
        setCpo("");
        setCpoDisable(false);
        setOnDisable(false);
    };

    const getRows = (data) => {
        const tempRows = [];
        data.forEach((order) => {
            const tempOrderRow = {};
            orderRowKey.forEach((key) => {
                tempOrderRow[key] = { value: order[key] };
            });
            tempRows.push(tempOrderRow);
        });
        setRows(tempRows);
    };

    const handleSearch = async () => {
        try {
            setLoading(true);
            const data = await fetchOrderInfobyONorPO(orderNumber, cpo);
            getRows(data);
            setOrders(data);
            if (data.length === 1) {
                setSelectedOrder(data[0]);
                navigate(`/order-detail/${data[0].orderNumber}`);
            }
            setLoading(false);
            setSearched(true);
        } catch (error) {
            setLoading(false);
            setErrorMessage("Fetch orders failed. Please try again", error.message);
            console.error("Fetch orders failed. Please try again", error);
        }
    };

    const rowNavFunction = (row) => {
        setSelectedOrder(
            orders.find((order) => order.orderNumber === row.orderNumber.value)
        );
        navigate(`/order-detail/${row.orderNumber.value}`);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <div>
            {loading && <Spinner />}
            {userInfo ? (
                <div className="search-page-layout-container">
                    <span className="search-retail-header">
                        {userInfo.retailName}
                    </span>
                    <div className="search-container">
                        <div className="search-title">
                            {t("search_page.title")}
                        </div>
                        <div className="search-description">
                            {t("search_page.description")}
                        </div>

                        <div className="search-input-group">
                            <div className="search-inputs-container">
                                <input
                                    className="input-area search-input-area"
                                    value={orderNumber}
                                    placeholder={t("search_page.on_label")}
                                    disabled={onDisable}
                                    onChange={(e) => handleValueChange(e, "on")}
                                    onKeyDown={handleKeyDown}
                                />
                                <input
                                    className="input-area search-input-area"
                                    value={cpo}
                                    placeholder={t("search_page.po_label")}
                                    disabled={cpoDisable}
                                    onChange={(e) =>
                                        handleValueChange(e, "cpo")
                                    }
                                    onKeyDown={handleKeyDown}
                                />
                                {erorrMessage && (
                                    <div className="search-error-message">
                                        {erorrMessage}
                                    </div>
                                )}
                            </div>
                            <div className="btn-group">
                                <div
                                    className="clear-btn"
                                    onClick={handleClear}
                                >
                                    <img
                                        src={
                                            require("../../assets/img/x.svg")
                                                .default
                                        }
                                        alt="clear"
                                    />
                                </div>
                                <button
                                    className="search-btn"
                                    onClick={handleSearch}
                                >
                                    {t("search_page.search_btn")}
                                </button>
                            </div>
                        </div>

                        {/* add table below */}
                        {searched && rows.length > 0 && (
                            <div className="result-container">
                                <div className="result-title">
                                    {t("search_page.results")}
                                </div>
                                <Table
                                    headers={searchResponseTableHeader}
                                    rows={rows}
                                    keys={orderRowKey}
                                    rowClickFunction={rowNavFunction}
                                ></Table>
                            </div>
                        )}
                        {searched && rows.length === 0 && (
                            <div className="result-container">
                                <div className="result-title">
                                    {t("search_page.results")}
                                </div>
                                <div>{t("table.empty_state")}</div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default SearchPage;
