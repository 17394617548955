import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { loginUser } from "../../services/api";
import Spinner from "../../components/spinner/spinner";
import "./login-page.css";

const LoginPage = ({ setUserInfo }) => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState("");
    const [erorrMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const passwordInputRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            setLoading(true);
            const data = await loginUser(username, password);
            setUserInfo(data);
            localStorage.setItem('userInfo', JSON.stringify(data));
            setLoading(false);
            navigate("/search");
        } catch (error) {
            setErrorMessage(error.toString());
            setLoading(false);
            console.error("Login failed. Please try again.", error);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    };

    const handleKeyDownUsername = (e) => {
        if (e.key === "Enter") {
            passwordInputRef.current.focus();
        }
    };

    const handleEyeClick = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {loading && <Spinner />}
            <div className="container">
                <div className="login-card-container">
                    <div className="card-title">{t("login_page.title")}</div>
                    <div className="card-helper">
                        {t("login_page.helper_text")}
                    </div>
                    <div className="input-group">
                        <input
                            className="input-area search-input-area"
                            value={username}
                            placeholder={t("login_page.username")}
                            onChange={(e) => {
                                setUserName(e.target.value);
                            }}
                            onKeyDown={handleKeyDownUsername}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            className="input-area search-input-area"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            placeholder={t("login_page.password")}
                            ref={passwordInputRef}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onKeyDown={handleKeyDown}
                        />
                        <span className="icon-follow" onClick={handleEyeClick}>
                            <img
                                src={
                                    showPassword
                                        ? require("../../assets/img/eye-off.svg")
                                              .default
                                        : require("../../assets/img/eye.svg")
                                              .default
                                }
                                alt=""
                            />
                        </span>
                    </div>
                    {erorrMessage && (
                        <div className="error-message">{erorrMessage}</div>
                    )}
                    <button className="login-btn" onClick={handleLogin}>
                        {t("login_page.login_btn")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
