import { useTranslation } from "react-i18next";
import "./backorder-status.css";

const BackorderStatus = ({ boValue, boComment }) => {
    const { t } = useTranslation();
    return (
        <div className="bo-container">
            <label className="bo-label">
                {boValue ? t("table.bo_true") : t("table.bo_false")}
            </label>
            {boValue && (
                <img
                    className="bo-img"
                    src={require("../../assets/img/warning.svg").default}
                    alt={t("table.bo_warning_alt")}
                />
            )}
            {boValue && <div className="bo-comment-tooltip">{boComment}</div>}
        </div>
    );
};

export default BackorderStatus;
