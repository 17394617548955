import React, { useEffect } from "react";
import "./modal.css";

const Modal = ({ isOpen, onClose, modalTitle, modalContent }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-overlay">
                <div
                    className="modal-content"
                    onClick={(e) => e.stopPropagation()}
                >
                    <button onClick={onClose} className="close-btn">
                        <img
                            src={require("../../assets/img/close.svg").default}
                            alt=""
                        />
                    </button>
                    <h2>{modalTitle}</h2>
                    <div className="modal-content-wrapper">{modalContent}</div>
                </div>
            </div>
        </>
    );
};

export default Modal;
