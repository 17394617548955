import OrderPageHeader from "../order-page-header/order-page-header";
import OrderPageFooter from "../order-page-footer/order-page-footer";
import "./order-page-layout.css";

const OrderPageLayout = ({ userInfo, showBackLink, showFooter, children }) => {
    return (
        <div className="page-container">
            <OrderPageHeader userInfo={userInfo} showBackLink={showBackLink} />
            <div className="page-content-container">
                {children}                
            </div>
            {showFooter && <OrderPageFooter />}
        </div>
    );
};

export default OrderPageLayout;
