import { useTranslation } from "react-i18next";
import "./order-page-footer.css";

const OrderPageFooter = () => {
    const { t } = useTranslation();

    const handleClick = () => {
        const email = 'service@kohltech.com';
        const mailtoLink = `mailto:${email}`;
        const anchor = document.createElement('a');
        anchor.href=mailtoLink;
        anchor.target = '_blank';
        anchor.click();
    }

    return (
        <footer className="footer-container">
            <h2 className="footer-title">{t("footer.title")}</h2>
            <div className="footer-content-container">
                <div
                    className="footer-content"
                    dangerouslySetInnerHTML={{
                        __html: t("footer.contact_description"),
                    }}
                ></div>
                <button className="footer-contact-btn" onClick={handleClick}>
                    {t("footer.contact_btn")}
                </button>
            </div>
        </footer>
    );
};

export default OrderPageFooter;
