import './summary-box.css';

const SummaryBox = ({ summaryArray }) => {
    return (
        <div className="order-detail-summary-box">
            {summaryArray.map((item, index) => (
                <div className="order-detail-summary-box-item" key={index}>
                    <div className="order-detail-summary-box-item-label">
                        {item.label}
                    </div>
                    <div className="order-detail-summary-box-item-value">
                        {item.value}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SummaryBox;
