import { fetchOrderInfobyONorPOMock, loginUserMock } from "./mockApi";
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL;
const USE_MOCK = process.env.REACT_APP_USE_MOCK_API;

export const isTokenExpired = (token) => {
    if (!token) return true;
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now()/1000;
        return decodedToken.exp < currentTime;
    } catch (err) {
        console.error("error decoding: ", err);
        return true;
    }
}

export const getToken = () => {
    return localStorage.getItem("jwtToken");
};

// Login API request
export const loginUser = async (username, password) => {
    if (USE_MOCK === "true") {
        return loginUserMock(username, password);
    }

    try {
        const response = await fetch(`${API_URL}/users/login`, {
            // method: "GET",
            "Content-Type": "application/json",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            throw new Error("Login Failed");
        }
        const data = await response.json();
        if (data.jwtToken) {
            localStorage.setItem("jwtToken", data.jwtToken);
        }
        return data;
    } catch (error) {
        console.error("Error during login: ", error);
        throw error;
    }
};

const apiFetch = async (url, options = {}) => {
    const token = getToken();

    if (isTokenExpired(token)) {
        localStorage.clear();
        window.location.href = '/';
        return;
    }

    const headers = {
        "Content-Type": "application/json",
        ...options.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };

    const response = await fetch(url, {
        ...options,
        headers,
    });

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
};

// get order info by using order number| Custom PO
export const fetchOrderInfobyONorPO = async (orderNumber, cPO) => {
    if (USE_MOCK === "true") {
        return fetchOrderInfobyONorPOMock(orderNumber, cPO);
    }
    try {
        if (orderNumber === '' || !orderNumber) {
            return await apiFetch(
                `${API_URL}/order?customerPO=${cPO}`
            );
        }
        return await apiFetch(
            `${API_URL}/order?ON=${orderNumber}`
        );
    } catch (error) {
        console.error("Error fetching order info by ON: ", error);
        throw error;
    }
};
