import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./status-pill.css";

const StatusPill = ({ status, collapseValue, isMobile }) => {
    const { t } = useTranslation();
    const [pill, setPill] = useState({
        statusLabel: "",
        statusIconSrc: "",
        statusIconAlt: "",
        statusValue: "",
    });

    const getIcon = (collapseValue) => {
        if (collapseValue) {
            return isMobile
                ? require("../../assets/img/collapse_left.svg").default
                : require("../../assets/img/collapse_up.svg").default;
        }
        return isMobile
            ? require("../../assets/img/collapse_right.svg").default
            : require("../../assets/img/collapse_down.svg").default;
    };

    useEffect(() => {
        if (status === "collapse") {
            setPill({
                statusValue: "collapse",
                statusLabel: collapseValue
                    ? t("status.collapse")
                    : t("status.expend"),
                statusIconSrc: getIcon(collapseValue),
                statusIconAlt: t("order_detail_page.collapse_icon"),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapseValue]);

    useEffect(() => {
        getStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const getStatus = () => {
        switch (status) {
            case "collapse":
                setPill({
                    statusValue: "collapse",
                    statusLabel: t("status.expend"),
                    statusIconSrc: getIcon(collapseValue),
                    statusIconAlt: t("order_detail_page.collapse_icon"),
                });
                break;
            case "shipped":
                setPill({
                    statusValue: "shipped",
                    statusLabel: t("status.shipped"),
                    statusIconSrc: require("../../assets/img/car.svg").default,
                    statusIconAlt: t("status.icon_alt"),
                });
                break;
            case "finished":
                setPill({
                    statusValue: "finished",
                    statusLabel: t("status.finished"),
                    statusIconSrc: require("../../assets/img/finished.svg")
                        .default,
                    statusIconAlt: t("status.icon_alt"),
                });
                break;
            case "production":
                setPill({
                    statusValue: "production",
                    statusLabel: t("status.production"),
                    statusIconSrc: require("../../assets/img/production.svg")
                        .default,
                    statusIconAlt: t("status.icon_alt"),
                });
                break;
            case "ordered":
                setPill({
                    statusValue: "ordered",
                    statusLabel: t("status.ordered"),
                    statusIconSrc: require("../../assets/img/doc.svg").default,
                    statusIconAlt: t("status.icon_alt"),
                });
                break;
            default:
                break;
        }
        return "";
    };

    return (
        <div className={`pill-container ${pill.statusValue}`}>
            <label className="pill-label">{pill.statusLabel}</label>
            <div className="pill-icon-container">
                <img
                    className="pill-icon"
                    src={pill.statusIconSrc}
                    alt={pill.statusIconAlt}
                />
            </div>
        </div>
    );
};

export default StatusPill;
