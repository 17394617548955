import TableCard from "../table-card/table-card";
import "./table-card-wrapper.css";

const TableCardWrapper = ({
    headers,
    rows,
    isMobile,
    collapse,
    setCollapse,
}) => {
    return (
        <div className="table-card-wrapper">
            {rows.map((row, index) => {
                return (
                    <TableCard
                        key={index}
                        keys={headers}
                        item={row}
                        isMobile={isMobile}
                        collapse={collapse}
                        setCollapse={setCollapse}
                    />
                );
            })}
        </div>
    );
};

export default TableCardWrapper;
