import { useEffect, useState } from 'react';
import './side-panel.css';



const SidePanel = ({open, setOpen, panelTitle, panelContent}) => {
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(()=> {
        if(open) {
            togglePanel();
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const togglePanel = () => {
        setIsOpen(!isOpen);
        setOpen(!isOpen);
    };

    return (
        <>
            {isOpen && <div className='overlay'></div>}
            <div className={`side-panel ${isOpen ? "open" : ""}`}>
                <button onClick={togglePanel} className="close-btn">
                    <img src={require('../../assets/img/close.svg').default} alt=""/>
                </button>
                <h2>{panelTitle}</h2>
                <div className="content-wrapper" dangerouslySetInnerHTML={{__html:panelContent}}></div>
            </div>
        </>
    );
};

export default SidePanel;
