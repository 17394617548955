export const loginResponse = {
    retailName: "Example Retail Name",
    jwtToken: "mockjwtToken123",
};

export const orderInfoMock = {
    retailName: "Test Retail Name",
    orderNumber: "1234567",
    customerPO: "PO12345",
    qouteName: "qoute_name_example_random_string", // will this be shown?
    tagName: "tag_name_example_random_string", // will this be shown?
    totalOrderQuantity: 50,
    totalShippedQuantity: 30,
    dateOrdered: "2024/09/24",
    deliveryDate: "2024/10/16", // Date | see below
    orderComment: "summery_comment_this_can_be_a_long_paragraph",
    lineItems: [
        {
            lineNumber: "001",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 1, 
            finished: 1, 
            shipped: 1, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 1,
                    shipped: 1,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                {
                    deliveryDate: "2024/10/22",
                    ordered: 1,
                    production: 1,
                    finished: 0,
                    shipped: 0,
                    backOrdered: true, 
                    backOrderComment: "this is the comment for BO",
                    comment: "for bathroom"
                },
                // ...
            ],
        },
        {
            lineNumber: "002",
            description: "SLCRV",
            width: 30,
            height: 40,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/21", // Date
            ordered: 3, // can sum all on FE
            production: 1, // can sum all on FE
            finished: 2, // can sum all on FE
            shipped: 2, // can sum all on FE
            backOrdered: true,
            backOrderComment: "something to display to show backorder info",
            lineDetail: { 
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, 
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 2,
                    production: 0,
                    finished: 2,
                    shipped: 2,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                {
                    deliveryDate: "2024/10/22",
                    ordered: 1,
                    production: 1,
                    finished: 0,
                    shipped: 0,
                    backOrdered: true, 
                    backOrderComment: "this is the comment for BO",
                    comment: "for bathroom"
                },
            ],
        },
        {
            lineNumber: "003",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/22", // Date
            ordered: 1, 
            production: 0, 
            finished: 1, 
            shipped: 1, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 1,
                    shipped: 1,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "004",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/23", // Date
            ordered: 1, 
            production: 0, 
            finished: 1, 
            shipped: 1, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 1,
                    shipped: 1,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "005",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/24", // Date
            ordered: 1, 
            production: 0, 
            finished: 1, 
            shipped: 1, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 1,
                    shipped: 1,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "006",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/25", // Date
            ordered: 1, 
            production: 0, 
            finished: 1, 
            shipped: 1, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 1,
                    shipped: 1,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "007",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/26", // Date
            ordered: 1, 
            production: 0, 
            finished: 1, 
            shipped: 1, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 1,
                    shipped: 1,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "008",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/27", // Date
            ordered: 1, 
            production: 0, 
            finished: 1, 
            shipped: 1, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 1,
                    shipped: 1,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "009",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/28", // Date
            ordered: 1, 
            production: 1, 
            finished: 1, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 1,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "010",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/29", // Date
            ordered: 1, 
            production: 1, 
            finished: 1, 
            shipped: 0, 
            backOrdered: true, // True | False
            backOrderComment: "this is now BO",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 1,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "011",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 1, 
            finished: 1, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 1,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "012",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 1, 
            finished: 1, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 1,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "013",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 1, 
            finished: 1, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 1,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "014",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 1, 
            finished: 0, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 0,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "015",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 1, 
            finished: 0, 
            shipped: 0, 
            backOrdered: true, // True | False
            backOrderComment: "will be finished soon",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 0,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "016",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 1, 
            finished: 0, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 1,
                    finished: 0,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "017",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 0, 
            finished: 0, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 0,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "018",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 0, 
            finished: 0, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 0,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "019",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 0, 
            finished: 0, 
            shipped: 0, 
            backOrdered: false, // True | False
            backOrderComment: "some thing to display to show backorder info",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 0,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        {
            lineNumber: "020",
            description: "3'0'' 2064 O/S",
            width: 36,
            height: 64,
            comment: "Customer left comments: this is a test. But we need a long text instead of this.",
            deliveryDate: "2024/10/20", // Date
            ordered: 1, 
            production: 0, 
            finished: 0, 
            shipped: 0, 
            backOrdered: true, // True | False
            backOrderComment: "this will be extended to the next year",
            lineDetail: {
                img: 'base64:images',
                description: 'model, size and all other detailed information'
            }, //line detail object
            items: [
                {
                    deliveryDate: "2024/10/20",
                    ordered: 1,
                    production: 0,
                    finished: 0,
                    shipped: 0,
                    backOrdered: false, 
                    backOrderComment: "",
                    comment: "nothing"
                },
                // ...
            ],
        },
        //...
    ],
};

export const ordersInfoMock = [
    orderInfoMock,
    {
        ...orderInfoMock,
        orderNumber: "7654321",
    },
];

export const orderHeaderMock = {
    orderNumber: '1234567',
    customerPO: 'CPO12345',
    totalOrderQuantity: 3,
    totalShippedQuantity: 3,
    dateOrdered: "2024/09/24",
    deliveryDate: "2024/10/16",
    orderComment: "mothing special",
    retailName: "test retail"
};

export const orderHeaderListMock = [
    orderHeaderMock,
    {
        ...orderHeaderMock,
        orderNumber: '8642013'
    }
]