import { useEffect, useState } from "react";
import "./sort-button.css";
import { useTranslation } from "react-i18next";

const SortButton = ({ clicked, label, btnOnClicked }) => {
    const upIcon = require("../../assets/img/sort_up.svg").default;
    const downIcon = require("../../assets/img/sort_down.svg").default;
    const {t} = useTranslation();
    const [isClicked, setIsClicked] = useState(false);
    const [imgIcon, setImgIcon] = useState(upIcon);

    useEffect(() => {
        setIsClicked(clicked);
        if (clicked) {
            setImgIcon(downIcon);
        } else {
            setImgIcon(upIcon);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clicked]);

    const handleClick = () => {
        if (imgIcon === upIcon) {
            setImgIcon(downIcon);
        } else {
            setImgIcon(upIcon);
        }
        setIsClicked(!isClicked);
        if (btnOnClicked) btnOnClicked(!isClicked);
    };

    return (
        <button
            className={isClicked ? "sort-btn clicked" : "sort-btn"}
            onClick={handleClick}
        >
            <label
                className={isClicked ? "sort-label label-clicked" : "sort-label"}
            >
                {label}
            </label>
            <img src={imgIcon} alt={t('order_detail_page.sort_icon')} />
        </button>
    );
};

export default SortButton;
