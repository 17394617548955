import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./components/layout/layout";
import LoginPage from "./pages/login-page/login-page";
import SearchPage from "./pages/search-page/search-page";
import OrderDerailPage from "./pages/order-detail-page/order-detail-page";
import "./App.css";

function App() {
    const [userInfo, setUserInfo] = useState(null);
    const [orders, setOrders] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        if (!userInfo) {
            setOrders(null);
            setSelectedOrder(null);
        }
    }, [userInfo]);
    return (
        <Router>
            <Layout user={userInfo} setUser={setUserInfo}>
                <Routes>
                    <Route
                        path="/"
                        element={<LoginPage setUserInfo={setUserInfo} />}
                    />
                    <Route
                        path="/search"
                        element={
                            <SearchPage
                                userInfo={userInfo}
                                setUserInfo={setUserInfo}
                                orders={orders}
                                setOrders={setOrders}
                                setSelectedOrder={setSelectedOrder}
                            />
                        }
                    />
                    <Route
                        path="/order-detail/:ordernumber"
                        element={
                            <OrderDerailPage
                                userInfo={userInfo}
                                setUserInfo={setUserInfo}
                                selectedOrder={selectedOrder}
                                orders={orders}
                                setOrders={setOrders}
                                setSelectedOrder={setSelectedOrder}
                            />
                        }
                    />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
