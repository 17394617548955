import Header from "../header/header";

const Layout = ({ user, setUser, children }) => {
    return (
        <div>
            <Header user={user} setUser={setUser} />
            <main>{children}</main>
        </div>
    );
};

export default Layout;
